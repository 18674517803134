import { AppBar, Box, Button, Link, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import nepalGovernmentLogo from "../../assets/img/nepal-government.png";
import { AppMisc } from "../../misc/appMisc";
import { PALIKA_NAME_FOR_WHICH_SYSTEM_TO_BE_DEPLOYED } from "../../utils/constants";
import styles from "./style";

export default function PublicHeader() {
  const classes = styles();
  let history = useHistory();
  let palikaName = PALIKA_NAME_FOR_WHICH_SYSTEM_TO_BE_DEPLOYED;

  function getPalikaLogo(palikaName) {
    switch (palikaName) {
      case "KANCHANPUR_KRISHNAPUR_MUN":
        return nepalGovernmentLogo;
      case "LAMJUNG_SUNDARBAJAR_MUN":
        return nepalGovernmentLogo;
      default: {
        return logo;
      }
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img src={getPalikaLogo(palikaName)} alt="जन-आवाज" width="36" onClick={() => { history.push("/"); }}></img>
        <Typography variant="h6" className={classes.brand}>
          <Link
            className={classes.link}
            onClick={() => {
              history.push("/");
            }}
          >
            {palikaName ? AppMisc.getMunicipalityName(palikaName) : "जन-आवाज"} - गुनासो व्यवस्थापन प्रणाली {palikaName ? "(हेलो मेयर)" : ""}
          </Link>
        </Typography>
        <Box display="flex" className={classes.menuItems} justifyContent="center" alignItems="center">
          <Link href="/faq" className={classes.navLink}>बारम्बार सोधिने प्रश्नहरु</Link>
          <Button variant="contained" color="primary" onClick={(e) => { history.push("/login"); }}>लग-इन</Button>
          <Button className={classes.signUpBtn} variant="outlined" color="primary" onClick={(e) => { history.push("/signup"); }}>Create Account</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
